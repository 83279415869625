import { TopicActivity } from '@models/topic-activity';
import { createReducer, on, Action } from '@ngrx/store';


import {
  loadTopic,
  setMessages,
  setStarEarned,
  setTopicActivities,
  setTopicIndex,
  setTopicLoaded,
  toggleActivitySaved
} from './topic-activity.actions';

export const topicActivityStateFeatureKey = 'topicActivityState';

export interface TopicActivityState {
  topicIndex: number;
  topicActivities: TopicActivity[];
  topicLoaded: boolean;
  activitySaved: boolean;
  starEarned: boolean;
  messages: string[];
}

export const initialState: TopicActivityState = {
  topicIndex: null,
  topicActivities: null,
  topicLoaded: false,
  activitySaved: false,
  starEarned: null,
  messages: null,
};

export const topicActivityReducers = createReducer(
  initialState,
  on(loadTopic, state => ({
    ...state, topicActivities: null, topicIndex: null, topicLoaded: false, activitySaved: false
  })),
  on(setStarEarned, (state, { starEarned }) => ({ ...state, starEarned })),
  on(setTopicActivities, (state, { topicActivities }) => ({ ...state, topicActivities, topicIndex: 0 })),
  on(setTopicIndex, (state, { index }) => ({ ...state, topicIndex: index })),
  on(setTopicLoaded, (state, { topicLoaded }) => ({ ...state, topicLoaded })),
  on(toggleActivitySaved, state => ({ ...state, activitySaved: !state.activitySaved })),
  on(setMessages, (state, { messages }) => ({ ...state, messages })),
);

export function reducer(state: TopicActivityState | undefined, action: Action) {
  return topicActivityReducers(state, action);
}
