
import { ActivityResult } from '@models/activity-result';
import { TopicActivity } from '@models/topic-activity';
import { TopicType } from '@models/topic-type';
import { createAction, props } from '@ngrx/store';

export enum TOPICACTIVITY_ACTIONS {
  loadTopic = '[TopicActivity] Load Topic',
  setStarEarned = '[TopicActivity]  Set Star Earned',
  setTopicActivities = '[TopicActivity] Set Topic Activities',
  setTopicIndex = '[TopicActivity] Set Topic Index',
  setTopicLoaded = '[TopicActivity] Set Topic Loaded',
  setMessages = '[TopicActivity] Set Messages',
  saveActivity = '[TopicActivity] Save Activity',
  toggleActivitySaved = '[TopicActivity] Toggle Activity Saved'
}

export const loadTopic = createAction(TOPICACTIVITY_ACTIONS.loadTopic, props<{ topicKey: string }>());
export const setStarEarned = createAction(TOPICACTIVITY_ACTIONS.setStarEarned, props<{ starEarned: boolean }>());
export const setTopicActivities = createAction(TOPICACTIVITY_ACTIONS.setTopicActivities, props<{
  topicActivities: TopicActivity[],
}>());
export const setTopicIndex = createAction(TOPICACTIVITY_ACTIONS.setTopicIndex, props<{ index: number }>());
export const setTopicLoaded = createAction(TOPICACTIVITY_ACTIONS.setTopicLoaded, props<{ topicLoaded: boolean }>());
export const setMessages = createAction(TOPICACTIVITY_ACTIONS.setMessages, props<{ messages: string[] }>());
export const saveActivity = createAction(TOPICACTIVITY_ACTIONS.saveActivity, props<{
  topicKey: string, payload: ActivityResult[], topicType?: TopicType
}>());
export const toggleActivitySaved = createAction(TOPICACTIVITY_ACTIONS.toggleActivitySaved);
